import { groupBy, isArray, pick } from 'lodash';
import { isValid } from 'date-fns';

import { ListRecord, NestedList, flattenNestedList } from '@/explore/grouping';
import { ChartType, Field, Grouping, SeriesViewOptions, Visualisation } from '@/explore/types';
import { Json } from '@/lib/types';
import { formatPropertyValue } from '@/explore/utils/format';

import { GroupedChartData, ChartData, TimeSeriesData, CategoryData, SeriesMetaData } from './types';
import { getChartColor } from '../utils';

const convertJsonToNum = (val: Json): number => parseFloat(JSON.stringify(val));

const getFieldLabel = (key: string, fields: Field[]): string => {
  const field = fields.find((field) => field.key === key);
  if (!field) {
    return key;
  }
  return field.name.length ? field.name : key;
};

interface AxisOptions {
  right: { keys: string[] };
}

type ChartDataGenerator<T extends ChartData> = (params: {
  records: NestedList;
  valueKeys: string[];
  fields: Field[];
  axisGroupKey: string;
  seriesGroupKey?: string;
  axes?: AxisOptions;
  seriesViewOptions?: SeriesViewOptions;
}) => T;

const generateValues = (valueKeys: string[], records: ListRecord[]) => {
  return valueKeys.reduce(
    (acc, valueKey) => ({
      ...acc,
      [valueKey]: convertJsonToNum(records[0][valueKey]),
    }),
    {} as Record<string, number>,
  );
};

const generateCombinedKey = (groupKey: string, valueKey: string) => `${groupKey}_${valueKey}`;

const generateCombinedValues = (valueKeys: string[], groupKey: string, records: ListRecord[]) =>
  Object.entries(groupBy(records, (record) => record[groupKey])).reduce(
    (acc, [groupValue, groupedRecords]) => ({
      ...acc,
      ...valueKeys.reduce(
        (acc, valueKey) => ({
          ...acc,
          [generateCombinedKey(groupValue, valueKey)]: convertJsonToNum(
            groupedRecords[0][valueKey],
          ),
        }),
        {} as Record<string, number>,
      ),
    }),
    {} as Record<string, number>,
  );

const generateSeries = (
  valueKeys: string[],
  fields: Field[],
  items: ChartData['items'],
  axes?: AxisOptions,
  seriesViewOptions?: SeriesViewOptions,
) =>
  valueKeys.map((valueKey, idx) => ({
    key: valueKey,
    label: getFieldLabel(valueKey, fields),
    axis: (axes?.right.keys.includes(valueKey) ?? false) ? ('right' as const) : ('left' as const),
    chartType: seriesViewOptions?.[valueKey]?.chartType ?? 'area',
    showValues: seriesViewOptions?.[valueKey]?.showValues ?? false,
    color: seriesViewOptions?.[valueKey]?.color ?? getChartColor(idx),
    minValue: Math.min(...items.map((item) => item.values[valueKey] ?? Infinity), Infinity),
    maxValue: Math.max(...items.map((item) => item.values[valueKey] ?? -Infinity), -Infinity),
  }));

const generateCombinedSeries = (
  valueKeys: string[],
  groupKey: string,
  records2: NestedList,
  fields: Field[],
  items: ChartData['items'],
  axes?: AxisOptions,
  seriesViewOptions?: SeriesViewOptions,
) => {
  let seriesIdx = 0;
  return Object.entries(groupBy(records2, (record) => record[groupKey])).flatMap(
    ([groupValue, groupedRecords]) =>
      valueKeys.map((valueKey) => {
        const combinedKey = generateCombinedKey(groupValue, valueKey);
        return {
          key: combinedKey,
          label:
            valueKeys.length > 1
              ? `${getFieldLabel(groupKey, fields)}: ${formatPropertyValue(groupedRecords[0][groupKey])} » ${getFieldLabel(valueKey, fields)}`
              : `${fields.find((field) => field.key === groupKey)?.name}: ${formatPropertyValue(groupedRecords[0][groupKey])}`,
          axis:
            (axes?.right.keys.includes(valueKey) ?? false) ? ('right' as const) : ('left' as const),
          chartType: seriesViewOptions?.[valueKey]?.chartType ?? 'area',
          showValues: seriesViewOptions?.[valueKey]?.showValues ?? false,
          color: seriesViewOptions?.[valueKey]?.color ?? getChartColor(seriesIdx++),
          minValue: Math.min(
            ...items.map((item) => item.values[combinedKey] ?? Infinity),
            Infinity,
          ),
          maxValue: Math.max(
            ...items.map((item) => item.values[combinedKey] ?? -Infinity),
            -Infinity,
          ),
        };
      }),
  );
};

const findMax = (acc: number, item: { values: Record<string, number> }) =>
  Math.max(acc, Math.max(...Object.values(item.values), -Infinity));

const findMin = (acc: number, item: { values: Record<string, number> }) =>
  Math.min(acc, Math.min(...Object.values(item.values), Infinity));

export const generateTimeseriesChartData: ChartDataGenerator<TimeSeriesData> = ({
  records,
  valueKeys,
  fields,
  axisGroupKey,
  seriesGroupKey,
  axes,
  seriesViewOptions,
}) => {
  const items = Object.entries(
    groupBy(flattenNestedList(records), (record) => record[axisGroupKey]),
  )
    .map(([axisGroupValue, records]) => {
      const date = new Date(axisGroupValue);

      return {
        dateValue: date,
        label: date.toLocaleDateString(),
        values:
          seriesGroupKey === undefined
            ? generateValues(valueKeys, records)
            : generateCombinedValues(valueKeys, seriesGroupKey, records),
      };
    })
    .filter((item) => isValid(item.dateValue))
    .sort((a, b) => {
      if (a.dateValue < b.dateValue) {
        return -1;
      }
      if (b.dateValue < a.dateValue) {
        return 1;
      }
      return 0;
    });

  const minValue = items.reduce(findMin, Infinity);
  const maxValue = items.reduce(findMax, -Infinity);

  const series =
    seriesGroupKey === undefined
      ? generateSeries(valueKeys, fields, items, axes, seriesViewOptions)
      : generateCombinedSeries(
          valueKeys,
          seriesGroupKey,
          records,
          fields,
          items,
          axes,
          seriesViewOptions,
        );

  return {
    maxValue,
    minValue,
    series,
    items,
  };
};

export const generateBarChartData: ChartDataGenerator<CategoryData> = ({
  records,
  valueKeys,
  fields,
  axisGroupKey,
  seriesGroupKey,
  axes,
}) => {
  records = flattenNestedList(records);

  const items = Object.values(groupBy(flattenNestedList(records), (record) => record[axisGroupKey]))
    .map((records) => ({
      categoryValue: formatPropertyValue(records[0][axisGroupKey]),
      label: getFieldLabel(axisGroupKey, fields),
      key: axisGroupKey,
      values:
        seriesGroupKey === undefined
          ? generateValues(valueKeys, records)
          : generateCombinedValues(valueKeys, seriesGroupKey, records),
    }))
    .sort((a, b) => {
      if (a.categoryValue < b.categoryValue) {
        return -1;
      }
      if (b.categoryValue < a.categoryValue) {
        return 1;
      }
      return 0;
    });

  const maxValue = items.reduce(findMax, -Infinity);
  const minValue = items.reduce(findMin, Infinity);

  const series =
    seriesGroupKey === undefined
      ? generateSeries(valueKeys, fields, items, axes)
      : generateCombinedSeries(valueKeys, seriesGroupKey, records, fields, items, axes);

  return {
    maxValue,
    minValue,
    series,
    items,
  };
};

export const generateGroupedTimeSeriesData = (
  records: NestedList,
  visualisation: Visualisation,
  groups: Grouping[],
  fields: Field[],
  axes?: AxisOptions,
): GroupedChartData<TimeSeriesData> => {
  if (visualisation.mainAxisKey === undefined) {
    throw new Error('Axis key missing');
  }

  if (groups.length > 2) {
    return records.map((item) => {
      if (item.$children === undefined) {
        throw new Error('Unexpected end of data');
      }
      return {
        type: 'group' as const,
        label: formatPropertyValue(item[groups[0].key]),
        items: generateGroupedTimeSeriesData(
          item.$children,
          visualisation,
          groups.slice(1),
          fields,
          axes,
        ),
      };
    });
  }

  return {
    type: 'data',
    chartData: generateTimeseriesChartData({
      records,
      valueKeys: visualisation.valueKeys,
      fields,
      axisGroupKey: visualisation.mainAxisKey,
      seriesGroupKey: groups.at(-2)?.key,
      axes,
      seriesViewOptions: visualisation.viewOptions?.series,
    }),
  };
};

export const generateGroupedCategoryData = (
  records: NestedList,
  visualisation: Visualisation,
  groups: Grouping[],
  fields: Field[],
): GroupedChartData<CategoryData> => {
  if (visualisation.mainAxisKey === undefined) {
    throw new Error('Axis key missing');
  }

  if (groups.length > 2) {
    return records.map((item) => {
      if (item.$children === undefined) {
        throw new Error('Unexpected end of data');
      }
      return {
        type: 'group' as const,
        label: formatPropertyValue(item[groups[0].key]),
        key: groups[0].key,
        items: generateGroupedCategoryData(item.$children, visualisation, groups.slice(1), fields),
      };
    });
  }

  return groups.length > 1
    ? {
        type: 'data',
        chartData: generateBarChartData({
          records,
          valueKeys: visualisation.valueKeys,
          fields,
          axisGroupKey: groups.at(-2)?.key ?? '',
          seriesGroupKey: visualisation.mainAxisKey,
        }),
      }
    : {
        type: 'data',
        chartData: generateBarChartData({
          records,
          valueKeys: visualisation.valueKeys,
          fields,
          axisGroupKey: visualisation.mainAxisKey,
        }),
      };
};

const filterSeriesByCondition =
  (condition: { axis?: 'left' | 'right'; chartType?: ChartType }) =>
  ({ axis, chartType }: { axis?: 'left' | 'right'; chartType?: ChartType }) => {
    return (
      (condition.axis === undefined || axis === condition.axis) &&
      (condition.chartType === undefined || chartType === condition.chartType)
    );
  };

export const getMinValue = (
  data: GroupedChartData,
  condition: { axis?: 'left' | 'right'; chartType?: ChartType } = {},
): number =>
  getSeries(data)
    .filter(filterSeriesByCondition(condition))
    .reduce((acc, { minValue }) => Math.min(acc, minValue), Infinity);

export const getMaxValue = (
  data: GroupedChartData,
  condition: { axis?: 'left' | 'right'; chartType?: ChartType } = {},
): number =>
  getSeries(data)
    .filter(filterSeriesByCondition(condition))
    .reduce((acc, { maxValue }) => Math.max(acc, maxValue), -Infinity);

export const getMaxStackedValue = (
  data: GroupedChartData,
  condition: { axis?: 'left' | 'right' } = {},
): number => {
  if (isArray(data)) {
    return Math.max(...data.map((item) => getMaxStackedValue(item.items, condition)));
  }

  return Math.max(
    ...['area' as const, 'line' as const, 'bar' as const].map((chartType) => {
      const valueKeys = data.chartData.series
        .filter(filterSeriesByCondition({ ...condition, chartType }))
        .map(({ key }) => key);
      return Math.max(
        ...data.chartData.items.flatMap((item) =>
          Object.values(pick(item.values, valueKeys)).reduce((acc, value) => acc + value, 0),
        ),
      );
    }),
  );
};

export const getMinStackedValue = (
  data: GroupedChartData,
  condition: { axis?: 'left' | 'right' } = {},
): number => {
  if (isArray(data)) {
    return Math.min(...data.map((item) => getMaxStackedValue(item.items, condition)));
  }

  return Math.min(
    ...['area' as const, 'line' as const, 'bar' as const].map((chartType) => {
      const valueKeys = data.chartData.series
        .filter(filterSeriesByCondition({ ...condition, chartType }))
        .map(({ key }) => key);
      return Math.min(
        ...data.chartData.items.flatMap((item) =>
          Object.values(pick(item.values, valueKeys)).reduce((acc, value) => acc + value, 0),
        ),
      );
    }),
  );
};

export const getValueKeys = (data: GroupedChartData, axis?: 'left' | 'right'): string[] =>
  getSeries(data)
    .filter((series) => axis === undefined || series.axis === axis)
    .map((series) => series.key);

export const getSeries = (
  data: GroupedChartData,
  condition: { axis?: 'left' | 'right' } = {},
): SeriesMetaData[] =>
  isArray(data)
    ? data.reduce<SeriesMetaData[]>(
        (acc, item) => [...acc, ...getSeries(item.items, condition)],
        [],
      )
    : data.chartData.series.filter(filterSeriesByCondition(condition));
